import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


import { environment } from '../../environments/environment';
import { BrowserService } from '../core/browser.service';
import { LoggerService } from '../core/logger.service';

import { AnalyticsService } from './analytics.service';
import { Branch, BranchApiKey } from './branch.provider';

const deepLinkKeys = [
    'sharedMediaId',
    'channel',
    'org_id',
];

@Injectable()
/**
 * A thin wrapper around Branch.io (BranchMetrics)'s Smart Banner SDK, for improved testability.
 *
 * @see https://github.com/BranchMetrics/Smart-App-Banner-Deep-Linking-Web-SDK
 */
export class SmartBannerService {
    /** @type {number} The height of the smart banner, in pixels */
    readonly height = 76; // in pixels
    public isShowing$: Observable<Boolean>;

    private _appHostname: string;
    private _branchSDK: any;
    private _branchApiKey: string;
    private _branchConfigurationOptions: object;
    private _deepLinkParams = new HttpParams();
    private _isBannerShowingState$ = new BehaviorSubject(false);

    constructor(@Inject(Branch) branch, @Inject(BranchApiKey) branchApiKey, private _browser: BrowserService, private _analytics: AnalyticsService, private _logger: LoggerService) {
        this._appHostname = environment.appHostname.replace(/\/$/, '');
        const cdnHostname = environment.cdnImagePath.replace(/\/$/, '');

        this._branchSDK = branch;
        this._branchApiKey = branchApiKey;
        this._branchConfigurationOptions = {
            icon: `${cdnHostname}/img/logos/apple-icon-180x180.png`,
            title: 'NPR One',
            description: 'FREE',
            rating: 4,
            openAppButtonText: 'Open',
            downloadAppButtonText: 'Download',
            iframe: false,
            forgetHide: 7,
            showBlackberry: false,
            showDesktop: false,
            respectDNT: false,
        };

        this.isShowing$ = this._isBannerShowingState$.pipe(distinctUntilChanged());
    }

    /**
     * Initializes the smart banner. Appropriate to be called in the `ngOnInit()` function of a component.
     */
    init(): void {
        this._parseQueryParameters();

        if (this._branchSDK && this._branchApiKey) {
            try {
                this._branchSDK.init(this._branchApiKey);

                this._branchSDK.addListener('didShowBanner', this._attachEventListenerToBannerButtonClick.bind(this));
                this._branchSDK.addListener('willCloseBanner', this._removeEventListenerFromBannerButtonClick.bind(this));
                this._branchSDK.addListener('didShowBanner', this._setBannerVisibility.bind(this, true));
                this._branchSDK.addListener('didCloseBanner', this._setBannerVisibility.bind(this, false));
                this._branchSDK.addListener('willNotShowBanner', this._setBannerVisibility.bind(this, false));

                this._branchSDK.banner(this._branchConfigurationOptions, {
                    data: {
                        $deeplink_path: this.deepLinkPath,
                        $always_deeplink: true,
                        $og_redirect: this.metadataUrl,
                    },
                });
            } catch (e) {
                this._logger.error('SmartBannerService', e);
                this._branchSDK = null;
            }
        } else {
            this._logger.warn('SmartBannerService', 'Branch SDK was not loaded or no Branch API key is configured, so the smart banner will never be shown!');
        }
    }

    /**
     * Used to inform Branch that a user has logged in and should be identified by a specific ID.
     *
     * @param {string} userId A string uniquely identifying the user, often a user ID or email address
     */
    logIn(userId: string): void {
        if (this._branchSDK && this._branchApiKey) {
            this._branchSDK.setIdentity(userId);
        } else {
            this._logger.warn('SmartBannerService', 'Branch SDK was not loaded or no Branch API key is configured, so setting an identity is useless.');
        }
    }

    /**
     * The counter to `logIn()`; call this when the user has logged out to remove their identifying information from the session.
     */
    logOut(): void {
        if (this._branchSDK && this._branchApiKey) {
            this._branchSDK.logout();
        } else {
            this._logger.warn('SmartBannerService', 'Branch SDK was not loaded or no Branch API key is configured, so logging out is useless.');
        }
    }

    /**
     * If query param stationId exists, it trumps every other query param
     *
     * @returns {string}
     */
    get deepLinkPath() {
        let prefix = 'listen';
        if (this._deepLinkParams.has('org_id')) {
            prefix = 'localize';
        }
        const query = this._deepLinkParams.keys().length ? `?${this._deepLinkParams.toString()}` : '';
        return `${prefix}${query}`;
    }

    /**
     * @returns {string}
     */
    get metadataUrl() {
        const query = this._deepLinkParams.keys().length ? `/?${this._deepLinkParams.toString()}` : '';
        return `${this._appHostname}${query}`;
    }

    /**
     * Parses the current window's query parameters and extracts all deep link parameters that should be
     * passed to the smart banner.
     *
     * @TODO Refactor and actually make this private
     *
     * @private
     */
    _parseQueryParameters(): void {
        const queryParams = new HttpParams({ fromString: this.currentQueryString.replace(/^\?/, '') });
        const keys = queryParams.keys();
        keys.forEach(key => {
            const values = queryParams.getAll(key);
            values.forEach((value) => {
                if (deepLinkKeys.indexOf(key) !== -1) {
                    this._deepLinkParams = this._deepLinkParams.set(key, value);
                }
            });
        });
        this._logger.debug('SmartBannerService', 'Will use the following app deep link:', `nprone://${this.deepLinkPath}`);
    }

    /**
     * Sets whether or not the banner is visible. Intended to be used as an event listener or callback function.
     *
     * @param {boolean} isVisible
     * @private
     */
    _setBannerVisibility(isVisible: boolean): void {
        this._isBannerShowingState$.next(isVisible);
    }

    /**
     * Attaches a click handler to the Smart Banner's "Download" button, if it exists.
     *
     * @private
     */
    _attachEventListenerToBannerButtonClick(): void {
        const buttonElement = this.bannerDownloadButton;
        if (buttonElement) {
            buttonElement.addEventListener('click', this._analytics.sendEvent.bind(this, 'settings', 'upsell banner click'), false);
        }
    }

    /**
     * Removes the click handler from the Smart Banner's "Download" button, if it exists.
     *
     * @private
     */
    _removeEventListenerFromBannerButtonClick(): void {
        const buttonElement = this.bannerDownloadButton;
        if (buttonElement) {
            buttonElement.removeEventListener('click', this._analytics.sendEvent.bind(this, 'settings', 'upsell banner click'), false);
        }
    }

    /**
     * Returns a DOM node representing the Smart Banner's "Download" button, if it exists.
     *
     * @returns {HTMLElement|null}
     * @private
     */
    private get bannerDownloadButton(): HTMLElement | null {
        const document = this._browser.getDocument();
        return document.getElementById('branch-mobile-action');
    }

    private get currentQueryString(): string {
        const window = this._browser.getWindow();
        return window.location.search;
    }
}

export default SmartBannerService;
