export const sharedEnvironment = {
  'baseHref': '/',
  'apiSubdomain': 'stage4',
  'authHostname': 'https://one-d1.npr.org',
  'appHostname': 'https://one-d1.npr.org',
  'wwwHostname': 'https://www-s1.npr.org',
  'cdnStaticPath': '',
  'cdnImagePath': '',
  'branchApiKey': 'key_test_icj7FRhl50LmNJbsIVe7OpdmrrcT8Twz'
}

export default sharedEnvironment;