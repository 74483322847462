import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// import { environment } from '../../environments/environment';
import { CampaignService } from '../analytics/campaign.service';
import { SmartBannerService } from '../analytics/smart-banner.service';
import { BrowserService } from '../core/browser.service';
import { LoggerService } from '../core/logger.service';

 // id=goToPlayer is tied to bypass.component.ts to link components. Beware if changes are made.
@Component({
    selector: 'div#app',
    template: `
    <header class="site__header grid__row grid__row--flex grid__row--fixed-height grid__row--with-gutters" role="banner"
            [isNowPlayingActive]="isRouteActive('/')"
            [isExploreActive]="isRouteActive('/explore')"
            [isChangeStationActive]="isRouteActive('/stations')">
    </header>
    <main role="main" class="grid__row grid__row--flex">
        <div class="grid__column grid__column--flex">
            <section class="grid__row grid__row--flex site__content" role="document"
                     [class.site__content--expanded]="isRouteActive('/explore') || isRouteActive('/stations')">
                <router-outlet></router-outlet>
            </section>
            <player tabindex="-1" id="goToPlayer" class="grid__row grid__row--flex grid__row--fixed-height player"
                    [attr.data-hidden]="isRouteActive('/explore') || isRouteActive('/stations')"
                    [isBrowserTabActive]="isBrowserTabActive$ | async"
                    [isPlayerHidden]="isRouteActive('/explore') || isRouteActive('/stations')">
            </player>
        </div>
    </main>
    `,
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
/**
 * This is, for all intents and purposes, the root of the application. It is purposely left as stupid as possible,
 * deferring nearly all of the work to its sub-components.
 *
 * @implements {OnInit}
 */
export class AppComponent implements OnInit {
    public isBrowserTabActive$: Observable<boolean>;
    private _isSmartBannerShowing = false;
    private _isBrowserTabActiveState$ = new BehaviorSubject(true);

    // @TODO make _elementRef private & refactor tests
    constructor(public _elementRef: ElementRef, private _logger: LoggerService, private _campaignService: CampaignService, private _smartBanner: SmartBannerService, private _browser: BrowserService) {

        // @TODO figure out if we can also move the below safely into ngOnInit
        this._logger.setLogLevelBasedOnUrl();

        /** @type {Observable<boolean>} */
        this.isBrowserTabActive$ = this._isBrowserTabActiveState$.pipe(distinctUntilChanged());
    }

    /**
     * The actions that should be taken when a component is first initialized
     *
     * @see https://angular.io/docs/ts/latest/api/core/OnInit-interface.html
     */
    ngOnInit() {
        this._campaignService.parseQueryParameters();

        const modernizr = this._browser.getModernizr();
        if (modernizr && modernizr.pagevisibility === true) {
            this._browser.getWindow().document.addEventListener('visibilitychange', this._updateBrowserTabActiveState.bind(this), false);
        }

        const navigator = this._browser.getWindow().navigator;
        if (navigator && /(iPad|iPhone|iPod|Android|Linux)/.test(navigator.platform)) {
            this._logger.debug('AppComponent', 'Detected mobile browser');
            this._updateAppHeight();
            this._browser.getWindow().document.addEventListener('resize', this._updateAppHeight.bind(this), false);
            this._smartBanner.isShowing$.subscribe((isBannerShowing: boolean) => {
                this._isSmartBannerShowing = isBannerShowing;
                this._updateAppHeight();
            });
        }

        this._smartBanner.init();

        /* tslint:disable */
        /* istanbul ignore next: seriously, this is not worth writing a test for */
        // if (environment.production) {
        //     const appHostname = environment.appHostname.replace(/\/$/, '');
        //     console.log(" ");
        //     console.log(":::       ::: :::::::::: :::        ::::::::   ::::::::  ::::    ::::  ::::::::::      ::::::::::: ::::::::");
        //     console.log(":+:       :+: :+:        :+:       :+:    :+: :+:    :+: +:+:+: :+:+:+ :+:                 :+:    :+:    :+:");
        //     console.log("+:+       +:+ +:+        +:+       +:+        +:+    +:+ +:+ +:+:+ +:+ +:+                 +:+    +:+    +:+");
        //     console.log("+#+  +:+  +#+ +#++:++#   +#+       +#+        +#+    +:+ +#+  +:+  +#+ +#++:++#            +#+    +#+    +:+");
        //     console.log("+#+ +#+#+ +#+ +#+        +#+       +#+        +#+    +#+ +#+       +#+ +#+                 +#+    +#+    +#+");
        //     console.log(" #+#+# #+#+#  #+#        #+#       #+#    #+# #+#    #+# #+#       #+# #+#                 #+#    #+#    #+#");
        //     console.log("  ###   ###   ########## ########## ########   ########  ###       ### ##########          ###     ########");
        //     console.log("::::    ::: :::::::::  :::::::::        ::::::::  ::::    ::: ::::::::::");
        //     console.log(":+:+:   :+: :+:    :+: :+:    :+:      :+:    :+: :+:+:   :+: :+:");
        //     console.log(":+:+:+  +:+ +:+    +:+ +:+    +:+      +:+    +:+ :+:+:+  +:+ +:+");
        //     console.log("+#+ +:+ +#+ +#++:++#+  +#++:++#:       +#+    +:+ +#+ +:+ +#+ +#++:++#");
        //     console.log("+#+  +#+#+# +#+        +#+    +#+      +#+    +#+ +#+  +#+#+# +#+");
        //     console.log("#+#   #+#+# #+#        #+#    #+#      #+#    #+# #+#   #+#+# #+#");
        //     console.log("###    #### ###        ###    ###       ########  ###    #### ##########");
        //     console.log(" ");
        //     console.log(" ");
        //     console.log("For more information about who built this: " + appHostname + '/humans.txt');
        //     console.log(" ");
        //     console.log("NOTE: we're hiring! https://n.pr/tech-jobs");
        //     console.log(" ");
        //     console.log(" ");
        // }
        /* tslint:enable */
    }

    /**
     * @param {string} path
     * @returns {boolean}
     */
    isRouteActive(path) {
        // @TODO check back in the future to see if there is a better way to do this using the router
        const window = this._browser.getWindow();
        const pathname = window.location.pathname.replace(/^\/one\//, '/'); // to handle stage4 and dev boxes
        return pathname === path;
    }

    /**
     * The event listener function attached to the 'visibilitychange' event
     *
     * @private
     */
    _updateBrowserTabActiveState() {
        /* istanbul ignore if */ // we can't assign to a read-only property, so there's no way to fake this
        if (this._browser.getWindow().document.hidden) {
            this._isBrowserTabActiveState$.next(false);
        } else {
            this._isBrowserTabActiveState$.next(true);
        }
    }

    /**
     * The event listener function attached to the 'resize' event for Mobile Safari
     *
     * @listens {resize}
     * @private
     */
    _updateAppHeight() {
        this._logger.debug('AppComponent', 'Updating app height for iOS Safari');
        /* istanbul ignore else */ // this will only ever really happen on non-standard browsers, which is hard to fake
        if (this._browser.getWindow().innerHeight) {
            if (this._isSmartBannerShowing === true) {
                this._logger.debug('AppComponent', 'Smart app banner is visible.', 'Original window height:',
                    this._browser.getWindow().innerHeight, 'but using window height:', (this._browser.getWindow().innerHeight - this._smartBanner.height));
                this._elementRef.nativeElement.setAttribute('style', `height: ${this._browser.getWindow().innerHeight - this._smartBanner.height}px`);
            } else {
                this._logger.debug('AppComponent', 'Smart app banner is not visible.', 'Window height:', this._browser.getWindow().innerHeight);
                this._elementRef.nativeElement.setAttribute('style', `height: ${this._browser.getWindow().innerHeight}px`);
            }
        }
    }
}
export default AppComponent;
