import { Injectable } from '@angular/core';

import { BrowserService } from '../core/browser.service';
import { LoggerService } from '../core/logger.service';

@Injectable()
export class AnalyticsService {
    private _ga: UniversalAnalytics.ga;

    constructor(private _browser: BrowserService, private _logger: LoggerService) {
        this.loadGA();
    }

    get isAvailable(): boolean {
        return typeof this._ga === 'function';
    }

    /**
     * Records a custom event to send to Google Analytics. If included, optionalParameters should
     * consist of the eventLabel followed by the eventValue.
     *
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events#event_fields
     *
     * @param {string} eventCategory
     * @param {string} eventAction
     * @param {...string} [optionalParameters]
     */
    sendEvent(eventCategory: string, eventAction: string, ...optionalParameters: any[]): void {
        if (optionalParameters.length > 2) {
            throw new TypeError('Google Analytics only supports 2 optional parameters: eventLabel and eventValue.');
        }

        if (!this.isAvailable) {
            this.loadGA(); // maybe it was slow to load
        }

        if (this.isAvailable) {
            this._ga('send', 'event', eventCategory, eventAction, ...optionalParameters);
            this._logger.debug('AnalyticsService', 'send', 'event', eventCategory, eventAction, ...optionalParameters);
        } else {
            this._logger.warn('AnalyticsService', 'Attempted to send an event to GA, but GA is unavailable.');
        }
    }

    /**
     * Records a custom pageview to send to Google Analytics.
     *
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#tracking_virtual_pageviews
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference#set
     *
     * @param {string} path
     * @param {string} [title]
     */
    sendPageview(path: string, title?: string): void {
        if (!this.isAvailable) {
            this.loadGA(); // maybe it was slow to load
        }

        if (this.isAvailable) {
            const params: UniversalAnalytics.FieldsObject = {
                page: path,
            };
            if (typeof title === 'string') {
                params.title = title;
            }

            this._ga('set', params);
            this._ga('send', 'pageview');
            this._logger.debug('AnalyticsService', 'set', params);
            this._logger.debug('AnalyticsService', 'send', 'pageview');
        } else {
            this._logger.warn('AnalyticsService', 'Attempted to send a pageview to GA, but GA is unavailable.');
        }
    }

    private loadGA(): void {
        this._ga = this._browser.getGA();
    }
}
export default AnalyticsService;
